<template>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card v-if="editedItem">
        <v-card-title>
          <span class="text-h5">
            {{$t('dtouch.request.title', locale)}}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                xs="12"
              >
                <v-text-field
                  :label="`${$t('dtouch.request.fullName', locale)}*`"
                  v-model="editedItem.FullName"
                  required
                  outlined
                  hide-details
                />
              </v-col>
              <v-col
                cols="12"
                xs="12"
              >
                <v-text-field
                  :label="`${$t('dtouch.request.email', locale)}*`"
                  v-model="editedItem.Email"
                  required
                  outlined
                  hide-details
                />
              </v-col>
              <v-col
                cols="12"
                xs="12"
              >
                <v-text-field
                  :label="`${$t('dtouch.request.room', locale)}*`"
                  v-model="editedItem.Room"
                  required
                  outlined
                  hide-details
                />
              </v-col>
              <v-col
                cols="12"
                xs="12"
              >
                <v-textarea
                  :label="`${$t('dtouch.request.comment', locale)}`"
                  v-model="editedItem.Comment"
                  required
                  outlined
                  hide-details
                />
              </v-col>
              <v-col
                v-if="isIssues"
                cols="12"
                xs="12"
              >
              <small>{{$t('dtouch.request.captureImage', locale)}}:</small>
                <v-card
                  elevation="0"
                  style="width: 100%"
                >
                  <div
                    v-if="!editedItem.Image"
                    style="width:100%;backgroundColor: #e0e0e0 !important;height: 120px;border-radius: 10px;border: 0.5px solid #56a09930 !important;cursor:pointer;"
                    @click="handleCaptureImage"
                  >
                    <div 
                      style="margin-top: 30px;width: 100%;"
                    >
                      <center><v-icon style="font-size: 60px;color: #FFFFFF80;">mdi-image-plus</v-icon></center>
                    </div>
                  </div>
                  <v-img
                    v-else
                    :src="editedItem.Image ? editedItem.Image : defaultImg"
                    height="120px"
                    :lazy-src="editedItem.Image"
                    aspect-ratio="1.7"
                    @click="handleCaptureImage"
                    class="grey lighten-2"
                    style="border-radius: 10px;border: 0.5px solid #56a09930 !important;"
                  />
                </v-card>
                <input 
                  v-show="false"
                  type="file" 
                  ref="myImage"
                  name="image" 
                  accept="image/*" 
                  capture="environment"
                  @change="handleUploadImage"
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="onClose"
          >
            {{$t('common.close', locale)}}
          </v-btn>
          <v-btn
            :disabled="loading"
            :color="config.BGColor"
            @click="onSave(editedItem)"
            :style="{ color: config.Color }"
          >
            {{$t('common.save', locale)}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    selectedID: {
      type: String,
      default: null,
    },
    workspaceID: {
      type: String,
      requried: true
    },
    locale: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      requried: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    onSave: {
      type: Function,
      required: true,
    },
    isIssues: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    editedItem: null,
  }),
  computed: {
    defaultImg () {
      return './img/default.png'
    }, 
  },
  watch: {
    selectedID (v) {
      if (v) {
        this.editedItem = {
          WorkspaceID: this.workspaceID,
          RequestsManagerID: v,
          IssuesManagerID: v,
          FullName: this.getStorage('f_name'),
          Email: this.getStorage('f_email'),
          Room: this.getStorage('f_room'),
          Comment: null,
          Image: null,
        }
        this.dialog = true
      } else {
        this.editedItem = null
        this.dialog = false
      }
    },
  },
  methods: {
    handleChangeImage (v) {
      this.editedItem.Image = v
    },
    handleCaptureImage () {
      this.$refs.myImage.click()
    },
    handleUploadImage () {
      const that = this
      const file = this.$refs.myImage.files[0]
      var reader  = new FileReader()
	
      reader.onloadend = function (){
        that.handleChangeImage(reader.result)
      }
    
      if (file) reader.readAsDataURL(file)
      else this.editedItem.Image = null
    },
  },
}
</script>

